<template lang="pug">
  footer.footer
    .container
      .content.has-text-centered
        p Presented with love from #[a(:href="'https://www.youtube.com/' + app.youtube.channel",target="_blank",rel="noopener noreferrer") {{ app.author }}].
        p
          a.icon(:href="'https://www.youtube.com/' + app.youtube.channel",target="_blank",rel="noopener noreferrer",aria-label="youtube")
            ion-icon(name="logo-youtube")

</template>

<script>
import config from '~config'

export default {
  computed: {
    app() {
      return config.app
    }
  }
}
</script>

<style lang="sass" scoped>
.social-buttons
  span
    margin: 0 3px  
</style>
