const baseUri = 'https://www.googleapis.com/youtube/v3'
const apiKey = 'AIzaSyDdr3vrCHs_23Lft0xFzZ4iT11Npfbro7M' //process.env.YOUTUBE_API_KEY
const title = 'Web Learn: it\'s like YouTube Gaming, but for Web Developers'
const tweet = encodeURIComponent(title)

export default {
  app: {
    name: 'Hanasaki Academy',
    url: 'https://hanasaki.academy/',
    title: title,
    description: '"Nhật Bản tiến" với bằng JLPT tiếng Nhật N2 cùng Hanasaki. Cùng Hanasaki cảm nhân Japanese flows in you.',
    author: 'Diệp Anh Đào',
    channelId: 'UCxkoXEI48A5lPPWtk7xfeAg',
    brand: {
      logo: 'static/images/logo.svg',
      logoMini: 'static/images/logo-mini.svg',
      banner: 'static/images/banner.png'
    },
    youtube: {
      username: 'HanasakiAcademy',
      channel: 'c/HanasakiAcademy'
    },
    facebook: {
      username: 'HanasakiAcademy',
      page: 'hanasaki.academy'
    },
    github: {
      username: 'freearhey',
      repo: 'freearhey/web-learn'
    },
    twitter: {
      username: 'arhey',
      tweet: tweet
    },
    links: [
    ]
  },
  api: {
    key: apiKey,
    search: {
      endpoint: baseUri + '/search',
      params: {
        key: apiKey,
        part: 'snippet',
        channelId: 'UCxkoXEI48A5lPPWtk7xfeAg',
        videoEmbeddable: true,
        safeSearch: 'strict',
        fields: 'prevPageToken,nextPageToken,items/id/videoId'
      }
    },
    videos: {
      endpoint: baseUri + '/videos',
      params: {
        key: apiKey,
        part: 'contentDetails,snippet,statistics',
        fields: 'items(contentDetails/duration,id,snippet(channelId,channelTitle,description,publishedAt,tags,thumbnails,title),statistics(dislikeCount,likeCount,viewCount)),nextPageToken,prevPageToken'
      }
    },
    channels: {
      endpoint: baseUri + '/channels',
      params: {
        key: apiKey,
        part: 'snippet,statistics,brandingSettings'
      }
    }
  }
}
