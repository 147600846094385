var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Typeahead" }, [
    _c("div", { staticClass: "control has-icon is-fullwidth" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query"
          }
        ],
        staticClass: "input is-medium is-expanded",
        attrs: {
          type: "text",
          autocomplete: "off",
          placeholder: _vm.placeholder,
          "aria-label": "search"
        },
        domProps: { value: _vm.query },
        on: {
          keyup: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.hit($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.reset($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.down($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.up($event)
            },
            _vm.update
          ],
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.query = $event.target.value
          }
        }
      }),
      _c(
        "i",
        { staticClass: "fa" },
        [_c("ion-icon", { attrs: { name: "search" } })],
        1
      )
    ]),
    _vm.hasItems && _vm.show
      ? _c(
          "div",
          { staticClass: "Typeahead__list panel" },
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "a",
              {
                key: index,
                staticClass: "Typeahead__list-item",
                class: {
                  "Typeahead__list-item--current": _vm.isCurrent(index)
                },
                on: {
                  mousemove: function($event) {
                    return _vm.setCurrent(index)
                  },
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.hit($event)
                  }
                }
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }