import videos from '~api/videos'
import search from '~api/search'
import channels from '~api/channels'
import topics from '~api/topics'

export default {
  videos,
  search,
  channels,
  topics
}
