var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("v-header"),
      _c("v-nav"),
      _c("router-view"),
      _c("vue-progress-bar"),
      _vm.errors.length
        ? _c(
            "div",
            _vm._l(_vm.errors, function(error, i) {
              return _c("alert", {
                key: i,
                attrs: {
                  type: "info",
                  message: error,
                  position: "bottom-right",
                  autohide: ""
                }
              })
            }),
            1
          )
        : _vm._e(),
      _c("v-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }