var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "content has-text-centered" }, [
        _c("p", [
          _vm._v("Presented with love from "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.youtube.com/" + _vm.app.youtube.channel,
                target: "_blank",
                rel: "noopener noreferrer"
              }
            },
            [_vm._v(_vm._s(_vm.app.author))]
          ),
          _vm._v(".")
        ]),
        _c("p", [
          _c(
            "a",
            {
              staticClass: "icon",
              attrs: {
                href: "https://www.youtube.com/" + _vm.app.youtube.channel,
                target: "_blank",
                rel: "noopener noreferrer",
                "aria-label": "youtube"
              }
            },
            [_c("ion-icon", { attrs: { name: "logo-youtube" } })],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }