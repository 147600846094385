var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "nav has-shadow" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "nav-item is-scrollable" },
        [
          _vm._l(_vm.topics, function(topic) {
            return _c(
              "router-link",
              {
                key: topic.id,
                staticClass: "button is-white",
                attrs: { to: { name: "topic", params: { id: topic.id } } }
              },
              [_c("strong", [_vm._v(_vm._s(topic.title))])]
            )
          }),
          _c(
            "router-link",
            {
              staticClass: "link more-link",
              attrs: { to: { name: "topics" } }
            },
            [_c("strong", [_vm._v("Show all..")])]
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-title" }, [
      _c("p", [_vm._v("Popular")]),
      _c("p", [_vm._v("topics")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }