var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "nav header" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "nav-left" },
        [
          _c(
            "router-link",
            { staticClass: "logo nav-item is-brand", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "is-hidden-mobile",
                attrs: { src: _vm.app.brand.logo, alt: _vm.app.name + " logo" }
              }),
              _c("img", {
                staticClass: "is-hidden-tablet",
                attrs: {
                  src: _vm.app.brand.logoMini,
                  alt: _vm.app.name + " logo"
                }
              })
            ]
          ),
          _c("search", { staticClass: "nav-item" })
        ],
        1
      ),
      _c("div", { staticClass: "nav-right is-hidden-mobile" }, [
        _c("div", { staticClass: "nav-item social-buttons" }, [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: {
                href: "https://www.youtube.com/" + _vm.app.youtube.channel,
                target: "_blank"
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("ion-icon", { attrs: { name: "logo-youtube" } })],
                1
              ),
              _c("span", [_vm._v("Youtube")])
            ]
          ),
          _c(
            "a",
            {
              staticClass: "button",
              attrs: {
                href: "https://www.facebook.com/" + _vm.app.facebook.page,
                target: "_blank"
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("ion-icon", { attrs: { name: "logo-facebook" } })],
                1
              ),
              _c("span", [_vm._v("Facebook")])
            ]
          ),
          _c(
            "a",
            {
              staticClass: "button",
              attrs: {
                href: "https://blog.hanasaki.academy/",
                target: "_blank"
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("ion-icon", { attrs: { name: "logo-wordpress" } })],
                1
              ),
              _c("span", [_vm._v("Blog")])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }