var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Search" },
    [
      _c("typeahead", {
        attrs: {
          items: _vm.suggestions,
          placeholder: "Search topics, channels, and videos"
        },
        on: { hit: _vm.search, type: _vm.fetchSuggestions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }