var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        mode: "out-in",
        "enter-active-class": _vm.enterClass,
        "leave-active-class": "fadeOut"
      }
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "Alert animated",
              class: _vm.classObject,
              on: {
                click: function($event) {
                  _vm.show = false
                }
              }
            },
            [_c("span", [_vm._v(_vm._s(_vm.message))])]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }